import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

function totalBeneficiary(props) {
  return (
    <>
      <DashboardCardStat
        title={props.total_beneficiary}
        description="Total Beneficiary"
        icon={
          <div className="circle-container">
            <FontAwesomeIcon icon={faGlobe} />
          </div>
        }
      />
    </>
  );
}

export default totalBeneficiary;
